<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 company-profile">
          <h1
            class="
              company-profile__title
              text-uppercase text-center
              font-weight-bold
              text-success
            "
          >
            {{ $t("Company Profile") }}
          </h1>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";

export default {
  name: "company-profile",
  components: {
    LargeLayout,
  },
};
</script>

<style lang="scss" scoped>
.company-profile {
  .company-profile__title {
    padding: 20px;
  }
}
</style>
